import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '../../services/device.service';
import { MatModalBaseComponent } from './mat-modal-base.component';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class MatModalService {
  constructor(
    public dialog: MatDialog,
    private deviceService: DeviceService
  ) {}

  openDialog<InputType = unknown, OutputType = unknown>(
    modalComponent: ComponentType<MatModalBaseComponent<InputType, OutputType>>,
    modalConfig: MatDialogConfig<InputType> = null,
    disableMobileClass = false
  ): Observable<OutputType> {
    const dialogRef = this.dialog.open<MatModalBaseComponent<InputType, OutputType>, InputType, OutputType>(
      modalComponent,
      modalConfig
    );

    if (!disableMobileClass) {
      this.addMobileClassToConfig(dialogRef as MatDialogRef<MatModalBaseComponent, unknown>);
    }

    return dialogRef.afterClosed();
  }

  addMobileClassToConfig(dialogRef: MatDialogRef<MatModalBaseComponent, unknown>) {
    // add mobile class
    dialogRef.addPanelClass(this.deviceService.isMobile ? 'mobile' : '');
  }
}
