import { Component, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mat-modal-base',
  template: '<ng-content></ng-content>',
})
export class MatModalBaseComponent<InputType = unknown, OutputType = unknown> {
  static modalConfig: MatDialogConfig = {};

  dialogRef: MatDialogRef<MatModalBaseComponent<InputType, OutputType>>;
  dialogData: InputType;

  constructor(protected injector: Injector) {
    this.dialogRef = this.injector.get<MatDialogRef<MatModalBaseComponent<InputType, OutputType>>>(
      MatDialogRef,
      null
    );
    this.dialogData = this.injector.get<InputType>(MAT_DIALOG_DATA, null);
  }

  closeModal(data?: OutputType): void {
    this.dialogRef.close(data);
  }
}
