import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatModalBaseComponent } from '../mat-modal-base.component';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  @Input() title = '';
  @Input() hideCloseIcon = false;
  @Input() dialogRef: MatDialogRef<MatModalBaseComponent>;

  closeClicked() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
