<div mat-dialog-title class="d-flex justify-content-between header-title">
    <div class="header-title-text">{{title | translate}}</div>
    <mat-icon
        *ngIf="!hideCloseIcon"
        (click)="closeClicked()"
        class="header-close-icon"
        appWithTestId
        [testIdPostfix]="'modal-close-icon'">
        close
    </mat-icon>
</div>
